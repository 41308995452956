import React, { useEffect, useState ,} from 'react';
import { useNavigate,Link,useParams} from 'react-router-dom';
import axios from 'axios';
import $ from 'jquery';
import 'datatables.net';
import "../style/tableStyle.css";

const TagListByPerson = () => {
    const [users, setUsers] = useState([]);
    const [namadepan,setNamaDepan] = useState("");
    const [dataFetched, setDataFetched] = useState(false);
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        getTagByUser();
    }, []);

    useEffect(() => {
        if (dataFetched) {
            $('#dataTable').DataTable(); // Initialize DataTables
        }
    }, [dataFetched]);


    const getTagByUser = async () => {
        try {
            console.log()
            const responseUser = await axios.get(`${process.env.REACT_APP_API_URL}/viewPersonById/${id}`);
            setNamaDepan(responseUser.data.namaDepan + " " + responseUser.data.namaTengah)
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewTagListByPersonId/${id}`);
            console.log(response)
            setUsers(response.data);
            console.log(response.data[0].personId)
            console.log(responseUser)
            setDataFetched(true); // Set flag to indicate data fetching is complete
        } catch (error) {
            console.error(error);
        }
    };

    const deleteTagList = async (id) => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/deleteTagList/${id}`);
            alert("successfully deleted")
        } catch (error) {
            console.error(error);
        }
    };
    

    return (
        <div className="column">
            <h1>Tag</h1>
            <div className="columns">
                <div className="column is-2">
                    <div className=' is-size-5 mb-3 has-text-weight-semibold'>Nama Jemaat</div>
                </div>
                <div className="column is-3">
                        <label className='control mb-2'>
                            <input
                                type="text"
                                className='input'
                                value={namadepan}
                                readOnly={true} // Menjadikan input menjadi readonly
                            />
                        </label>
                </div>
                <div className="column is-3">
                    <Link to={`/JemaatTagAdd/${id}`} className="button is-info is-small" style={{ marginRight: '10px' }}>Add Tag</Link>
                </div>
            </div>
                <table id="dataTable" className="table is-striped is-fullwidth mt-5">
                    <thead>
                        <tr>
                            <th>Action</th>
                            <th>Tag Name</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map(user => (
                            <tr key={user.tagId}>
                                <td>
                                    <Link to={`/TagListByTag/${user.tagId}`} className="button is-info is-small" style={{ marginRight: '10px' }}>Details</Link>
                                    <button className="button is-danger is-small" onClick={() => deleteTagList(user.tagListId)}>Delete</button>
                                </td>
                                <td>{user.tagName}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
        </div>
    );
};

export default TagListByPerson;
