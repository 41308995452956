import React,{useState,useEffect} from 'react'
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import '../style/UserAddStyle.css'


const EventParticipantAdd = () => {

    // Untuk data yang ke DB
    
    const [eventName, setEventName] = useState("");
    const [eventMember, setEventMember] = useState("");
    const [eventId, setEventId] = useState("");
    const [Discjourney, setDiscJourney] = useState("");
    const [Discjourney2, setDiscJourney2] = useState("");
    const [SyaratKelas, setSyaratKelas] = useState([]);


    const navigate = useNavigate();
    const {id} = useParams();
    
    // Untuk Sugest 
    const [suggested, setSuggested] = useState([]);
    let jemaatList = "";
    
    // Fetch list jemaat
    const getJemaat = async () => {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewPerson`);
        setSuggested(response.data);
        console.log(response)
        jemaatList = response.data;
        console.log(jemaatList);
    };
    
    // Fetch detail event
const getEvent = async () => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewEvent/${id}`);
        console.log(response)
        const eventData = response.data;

        setEventName(eventData.eventName);
        setEventId(eventData.eventId);
        const discjourney2 = eventData.discipleshipJourneyTypeId;
        setDiscJourney(discjourney2);
        setDiscJourney2(discjourney2);
        console.log(discjourney2)

        if (discjourney2) {
            console.log(discjourney2);
            const responseDJ = await axios.get(`${process.env.REACT_APP_API_URL}/viewDiscipleshipJourneyTypeById/${discjourney2}`);
            console.log(responseDJ.data);
            const prereq = responseDJ.data.prereq;
            setDiscJourney(prereq);
            console.log(prereq);

            if (prereq) {
                const responseDJ2 = await axios.get(`${process.env.REACT_APP_API_URL}/checkEventRegistrationByDj/${prereq}`);
                console.log(responseDJ2.data);

                // Ensure that responseDJ2.data is an array
                const syaratKelasArray = Array.isArray(responseDJ2.data) ? responseDJ2.data : [responseDJ2.data];
                setSyaratKelas(syaratKelasArray);
                console.log("SyaratKelas:", syaratKelasArray);
            } else {
                console.log("No prerequisite found.");
            }
        } else {
            console.log('Discipleship Journey Type ID is not available');
        }
    } catch (error) {
        console.error('Error fetching event or discipleship journey data:', error);
    }
};

const saveParticipant = async (e) => {
    e.preventDefault();
    try {
        // Fetch the current list of registrations
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewRegistrationList/${id}`);
        console.log(response);

        // Ensure SyaratKelas is an array and log it
        const syaratKelasArray = Array.isArray(SyaratKelas) ? SyaratKelas : [];
        console.log("SyaratKelas Array:", syaratKelasArray);

        // Extract the list of registered person IDs
        const registeredPersonIds = response.data.map(registration => registration.person.personId);

        // Check if the eventMember is already registered
        if (registeredPersonIds.includes(eventMember)) {
            alert("Participant is already registered.");
            return;
        }

        // Check if there are prerequisites and if the eventMember meets them
        let prerequisiteMet = true;
        if (syaratKelasArray.length > 0) {
            prerequisiteMet = syaratKelasArray.some(prereq => prereq.personId === eventMember && prereq.status === 'lulus');
            if (!prerequisiteMet) {
                alert("Participant has not completed the required class.");
                return;
            }
        }

        const userData = {
            eventId,
            personId: eventMember,
            discipleshipJourneyTypeId: Discjourney2
        };

        // Filter only properties with values
        const filteredUserData = Object.fromEntries(
            Object.entries(userData).filter(([_, value]) => value !== null && value !== undefined && value !== "")
        );
        console.log(filteredUserData);

        // Insert the new registration
        await axios.post(`${process.env.REACT_APP_API_URL}/insertEventRegistration`, filteredUserData);
        alert("Participant has been successfully registered.");
    } catch (error) {
        console.error('Error during registration:', error);
        alert("An error occurred during registration. Please try again later.");
    }
};


    //Use Effect
    useEffect(()=> {
        getJemaat();
        getEvent();
    },[]);

    
return (
    <div className="columns is-mobile mr-2">
        <div className="column ">
            <div className='is-size-4 mb-3 has-text-weight-bold'>Add Event Participant</div>
            {/* Nama */}
            <div className='is-size-5 mb-2 has-text-weight-bold'>Nama Event</div>
            <div className="field">
                <label className='control'>
                    <input
                        type="text"
                        className='input'
                        value={eventName} 
                        readOnly={true} 
                        placeholder='Nama Event'
                    />
                </label>
            </div>
            <form onSubmit={saveParticipant}action=''>
                <label className='control mb-2'>
                    <div className=' is-size-6 mb-2 has-text-weight-bold'>Nama Jemaat</div>
                        <select className='input' value={eventMember} onChange={(e) => setEventMember(e.target.value)}>
                            <option value=""> Nama Jemaat </option>
                            {/* Check if jemaatList is an array before mapping over it */}
                            {Array.isArray(suggested) && suggested.map((jemaat) => (
                                <option key={jemaat.personId} value={jemaat.personId}>
                                    {jemaat.namaDepan} {/* Menggunakan nilai jemaat.eventName sebagai teks opsi */}
                                </option>
                            ))}
                        </select>
                </label>
                <div className="control">
                    <button className='button is-success'>Save</button>
                </div>
            </form>
        </div>
    </div>
)
}
export default EventParticipantAdd