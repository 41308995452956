import React, { useEffect, useState } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import axios from 'axios';
import $ from 'jquery';
import 'datatables.net';
import "../style/tableStyle.css";

const JemaatFamily = () => {
    const [users, setUsers] = useState([]);
    const [dataFetched, setDataFetched] = useState(false);
    const [Father, setFather] = useState({ personId: '', namaDepan: '', namaTengah: '', namaBelakang: '' }); // Default kosong
    const [Mother, setMother] = useState({ personId: '', namaDepan: '', namaTengah: '', namaBelakang: '' }); // Default kosong
    const [Siblings, setSiblings] = useState([]); // Default array kosong

    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        getUsers();
    }, []);

    useEffect(() => {
        if (dataFetched) {
            $('#dataTable').DataTable(); // Initialize DataTables
        }
    }, [dataFetched]);

    const getUsers = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/personFamily/${id}`);
            console.log(response);
            setFather(response.data.father || { personId: '', namaDepan: '', namaTengah: '', namaBelakang: '' }); // Jika tidak ada data, atur default kosong
            setMother(response.data.mother || { personId: '', namaDepan: '', namaTengah: '', namaBelakang: '' }); // Jika tidak ada data, atur default kosong
            setSiblings(response.data.siblings || []); // Jika tidak ada data, atur array kosong
            setUsers(response.data);
            console.log(Siblings);
            setDataFetched(true); // Set flag to indicate data fetching is complete
        } catch (error) {
            console.error(error);
            // Tampilkan alert atau pesan bahwa tidak ada data keluarga yang ditemukan
            alert('Tidak ada data keluarga ditemukan.');
        }
    };

    const deleteUser = async (id) => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/deletePerson/${id}`);
            navigate("/");
        } catch (error) {
            console.error(error);
        }
    };

    const formatName = (person) => {
        return [person.namaDepan, person.namaTengah, person.namaBelakang].filter(Boolean).join(' ');
    };

    return (
        <div className="colum">
            <h1>Family Members</h1>
            {/* Father */}
            <div className="columns">
                <div className="column is-2">
                    <div className='is-size-5 mb-3 has-text-weight-semibold'>Father Name</div>
                </div>
                <div className="column is-9">
                    <div className='input' placeholder='Tempat'>
                        {formatName(Father)} (ID: {Father.personId})
                    </div>
                </div>
            </div>
            {/* Mother */}
            <div className="columns">
                <div className="column is-2">
                    <div className='is-size-5 mb-3 has-text-weight-semibold'>Mother Name</div>
                </div>
                <div className="column is-9">
                    <div className='input' placeholder='Tempat'>
                        {formatName(Mother)} (ID: {Mother.personId})
                    </div>
                </div>
            </div>
            {/* Siblings Table */}
            <table id="dataTable" className="table is-striped is-fullwidth mt-5">
                <thead>
                    <tr>
                        <th>Action</th>
                        <th>Name</th>
                        <th>Family Relation</th>
                        <th>ID</th>
                    </tr>
                </thead>
                <tbody>
                    {Siblings.map(user => (
                        <tr key={user.personId}>
                            <td>
                                <Link to={`/MessageDetailPerson/${user.MessageId}`} className="button is-info is-small" style={{ marginRight: '10px' }}>Details</Link>
                                <button className="button is-danger is-small" onClick={() => deleteUser(user.personId)}>Delete</button>
                            </td>
                            <td>{formatName(user)}</td>
                            <td>Siblings</td>
                            <td>{user.personId}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default JemaatFamily;
