import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { useTable, usePagination, useGlobalFilter, useSortBy } from 'react-table';
import "../style/tableStyle.css";
import "../style/EventListStyle.css";
import { exportToCSV } from '../Utils/csvUtils';

const EventList = () => {
    const [Events, setEvents] = useState([]);
    const [selectedEventType, setSelectedEventType] = useState("");
    const navigate = useNavigate();

    const getEvents = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewEventCommon&Service`);
            console.log(response.data);
            setEvents(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    const updateEvent = async (id) => {
        try {
            await axios.patch(`${process.env.REACT_APP_API_URL}/deactivateEvent/${id}`);
            window.location.reload();
        } catch (error) {
            console.log(error);
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        if (isNaN(date)) {
            console.error('Invalid date string:', dateString);
            return 'Invalid date';
        }

        return new Intl.DateTimeFormat('en-GB', {
            weekday: 'short',   // Short weekday name, e.g., "Mon"
            day: 'numeric',     // Numeric day, e.g., "3"
            month: 'short',      // Short month name, e.g., "Jan"
            year: 'numeric'      // Numeric year, e.g., "2024"
        }).format(date);
    };


    const checkData = (value) => {
        if (value === undefined || value === null || value === '') {
            return <em>No Data</em>;
        }
        return value;
    };

    useEffect(() => {
        getEvents();
    }, []);

    
    const columns = useMemo(() => [
        {
            Header: 'Event Name',
            accessor: 'eventName',
            Cell: ({ row }) => {
                const eventType = row.original.eventType;
                let linkTo = '';

                if (eventType === 'Service') {
                    linkTo = `/ServiceEventDetail/${row.original.eventId}`;
                } else if (eventType === 'CommonEvent') {
                    linkTo = `/EventDetail/${row.original.eventId}`;
                } else {
                    linkTo = `/EventDetail/${row.original.eventId}`;
                }

                return (
                    <Link to={linkTo} className="has-text-weight-bold" style={{ marginRight: '10px' }}>
                        {checkData(row.original.eventName)}
                    </Link>
                );
            }
        },
        { Header: 'Event Type', accessor: 'eventType', Cell: ({ value }) => checkData(value) },
        {
            Header: 'Event Date',
            accessor: 'eventDate',
            Cell: ({ value }) => checkData(formatDate(value)) // Apply date formatting
        },
        { Header: 'Event Time In', accessor: 'eventTimeIn', Cell: ({ value }) => checkData(value) },
        { Header: 'Event Time Out', accessor: 'eventTimeOut', Cell: ({ value }) => checkData(value) },
        { Header: 'Event Capacity', accessor: 'eventCapacity', Cell: ({ value }) => checkData(value) },
        { Header: 'Event Setup', accessor: 'eventSetup', Cell: ({ value }) => checkData(value) },
        { Header: 'Event Location', accessor: 'eventLokasi', Cell: ({ value }) => checkData(value) },
        {
            Header: 'Action',
            accessor: 'actions',
            Cell: ({ row }) => {
                const isActive = row.original.status === 'active'; // Check if status is 'active'
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <button
                            onClick={() => updateEvent(row.original.eventId)} // Pass the event ID
                            style={{ background: 'none', border: 'none', cursor: 'pointer', padding: '0' }}
                        >
                            {isActive ? (
                                <svg xmlns="http://www.w3.org/2000/svg" width="2.5em" height="2.5em" viewBox="0 0 24 24">
                                    <path fill="green" d="M17 7a5 5 0 0 1 0 10H7A5 5 0 1 1 7 7h10Zm-10 2a3 3 0 1 0 0 6a3 3 0 0 0 0-6Z">
                                        <animate fill="freeze" attributeName="d" dur="0.2s" values="M17 7a5 5 0 0 1 0 10H7A5 5 0 1 1 7 7h10Zm-10 2a3 3 0 1 0 0 6a3 3 0 0 0 0-6Z;M17 7a5 5 0 0 1 0 10H7A5 5 0 1 1 7 7h10Zm0 2a3 3 0 1 0 0 6a3 3 0 0 0 0-6Z" />
                                    </path>
                                </svg>
                            ) : (
                                <svg xmlns="http://www.w3.org/2000/svg" width="2.5em" height="2.5em" viewBox="0 0 24 24">
                                    <path fill="red" d="M17 7a5 5 0 0 1 0 10H7A5 5 0 1 1 7 7h10Zm0 2a3 3 0 1 0 0 6a3 3 0 0 0 0-6Z">
                                        <animate fill="freeze" attributeName="d" dur="0.2s" values="M17 7a5 5 0 0 1 0 10H7A5 5 0 1 1 7 7h10Zm0 2a3 3 0 1 0 0 6a3 3 0 0 0 0-6Z;M17 7a5 5 0 0 1 0 10H7A5 5 0 1 1 7 7h10Zm-10 2a3 3 0 1 0 0 6a3 3 0 0 0 0-6Z" />
                                    </path>
                                </svg>
                            )}
                        </button>
                    </div>
                );
            }
        }
    ], []);

    const filteredData = useMemo(() => {
        let data = Events;

        if (selectedEventType) {
            data = data.filter(event => event.eventType === selectedEventType);
        }

        console.log("Filtered Data:", data); // Debugging output
        return data;
    }, [Events, selectedEventType]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        state,
        setGlobalFilter,
        setPageSize
    } = useTable(
        { columns, data: filteredData, initialState: { pageIndex: 0, pageSize: 10 } },
        useGlobalFilter,
        useSortBy,  // useSortBy must come before usePagination
        usePagination // usePagination must come after useSortBy
    );

    const { globalFilter, pageIndex, pageSize } = state;

    return (
        <div className="columns">
            <div className="column">
                <div className="columns mt-2">
                    <div className="column is-7">
                        <div className="custom-button mr-2" onClick={() => navigate('/addEvent')}>Add Event</div>
                        <button className="custom-button mr-2" onClick={() => exportToCSV(Events, 'Event')}>Export to CSV</button>
                    </div>
                    <div className="column is-flex is-justify-content-flex-end">
                        <div className="field is-grouped">
                            <div className="control">
                                <select
                                    value={selectedEventType}
                                    onChange={(e) => setSelectedEventType(e.target.value)}
                                    className="custom-select"
                                >
                                    <option value="">All Event Types</option>
                                    <option value="CommonEvent">Common Event</option>
                                    <option value="Service">Service</option>
                                </select>
                            </div>
                            <div className="control">
                                <input
                                    className="custom-input"
                                    type="text"
                                    value={globalFilter || ''}
                                    onChange={e => setGlobalFilter(e.target.value)}
                                    placeholder="Search"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Entries per page selector */}
                <div className="field is-grouped is-grouped-multiline" style={{ display: 'flex', alignItems: 'center' }}>
                    <div className="control" style={{ marginRight: '10px' }}>
                        <div className="select">
                            <select
                                value={pageSize}
                                onChange={(e) => setPageSize(Number(e.target.value))}
                            >
                                {[10, 25, 50, 100].map(size => (
                                    <option key={size} value={size}>
                                        {size}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <label className="label" style={{ textAlign: 'center' }}>entries per page</label>
                </div>
                {/* Table */}
                <div className="table-container">
                    <table {...getTableProps()} className="table mt-5">
                        <thead>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                            {column.render('Header')}
                                            {/* Add sort indicator */}
                                            <span>
                                                {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : null}
                                            </span>
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map(cell => (
                                            <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                        ))}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                {/* Pagination */}
                <div className="pagination" style={{ display: 'flex', alignItems: 'center' }}>
                    {/* Left-aligned: Showing X to Y rows */}
                    <div style={{ flex: '1', textAlign: 'left' }}>
                        <span>
                            <strong>
                                Showing {page.length ? page[0].index + 1 : 0} to {page.length ? page[0].index + page.length : 0} 
                            </strong>
                        </span>
                    </div> 
                    {/* Center-aligned: Previous, Page Number, Next */}
                    <div style={{ display: 'flex', alignItems: 'center', flex: '1', justifyContent: 'center' }}>
                        <button className='button' onClick={() => previousPage()} disabled={!canPreviousPage}>
                            Previous
                        </button>
                        <span style={{ margin: '0 10px' }}>
                            <button className='custom-button'>
                                {pageIndex + 1}
                            </button>
                        </span>
                        <button className='button' onClick={() => nextPage()} disabled={!canNextPage}>
                            Next
                        </button>
                    </div>
                    {/* Right-aligned: Page X of Y */}
                    <div style={{ flex: '1', textAlign: 'right' }}>
                        <span>
                            <strong>
                                Page: {pageIndex + 1} of {pageOptions.length}
                            </strong>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EventList;
