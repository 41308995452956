import React, { useState,useEffect } from 'react';
import Select from 'react-select'
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import '../style/UserRegisterStyle.css'; // File CSS untuk styling khusus halaman login


const UserRegisterDataJemaat = () => {

    // Data
    const [formData, setFormData] = useState({
        namaDepan: "",
        namaTengah: "",
        namaBelakang: "",
        namaLain: "",
        gender: "",
        tanggalLahir: "",
        tempatLahir: "",
        faseHidup: "",
        statusPerkawinan: "",
        pasangan: "",
        tanggalPerkawinan: "",
        nomorTelepon: "",
        email: "",
        lokasiGereja: "",
        role: "",
        ayah: "",
        ibu: "",
        kerinduan: "",
        komitmenBerjemaat: "",
        provinsi: ""
    });

    const [account, setaccount] = useState({
        username: "",
        password: "",
        password2: "",
        email: "",
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleAccountChange = (e) => {
        const { name, value } = e.target;
        setaccount({
            ...account,
            [name]: value
        });
    };


    // Untuk Sugest 
    const [suggestedPasangan, setSuggestedPasangan] = useState([]);
    const [suggestedProvinsi, setSuggestedProvinsi] = useState([]);
    const [suggestedKota, setSuggestedKota] = useState([]);
    const [suggestedTag, setSuggestedTag] = useState([]);

    // Untuk Sugest OrangTua
    const [optionAyah, setoptionAyah] = useState([]);
    const [selectedAyah, setselectedAyah] = useState(null);
    const [optionIbu, setoptionIbu] = useState([]);
    const [selectedIbu, setselectedIbu] = useState(null);

    const [activeSection, setActiveSection] = useState('personal');


    // Ayah
    // select Ayah
    useEffect(() => {
        const fetchDJOptions = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewPerson`);

            // Memisahkana male dan female
            const males = response.data.filter(person => person.gender === 'Male');

            const options = males.map((dj) => ({
            value: dj.personId,
            label: dj.namaDepan+dj.namaTengah,
            }));
            console.log(options)
            setoptionAyah(options);
        } catch (error) {
            console.error('Error fetching discipleship journey types:', error);
        }
        };

        fetchDJOptions();
    }, []);
    // Handle select Ayah
    const handleAyahChange = (selectedOption) => {
        setselectedAyah(selectedOption);
        setFormData({
                ...formData,
                ayah: selectedOption.value // Update formData.ayah
            });
    };

    // Ibu
    // select Ibu
    useEffect(() => {
        console.log(formData)
        const fetchDJOptions = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewPerson`);

                // Memisahkana male dan female
                const males = response.data.filter(person => person.gender === 'Female');

                const options = males.map((dj) => ({
                    value: dj.personId,
                    label: dj.namaDepan + dj.namaTengah,
                }));
                console.log(options)
                setoptionIbu(options);
            } catch (error) {
                console.error('Error fetching discipleship journey types:', error);
            }
        };

        fetchDJOptions();
    }, []);
    // Handle select Ibu
    const handleIbuChange = (selectedOption) => {
        setselectedIbu(selectedOption);
        setFormData({
        ...formData,
        ibu: selectedOption.value // Update formData.ibu
    });
    };

    //Use Effect
    useEffect(()=> {
        getJemaat();
        getLokasi();
        getKota();
        console.log(formData)
    },[formData.provinsi]);
    

    
    // Fetch nama jemaat yang ada untuk suggested pasangan
    const getJemaat = async () => {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewPerson`);
        console.log(response)
        setSuggestedPasangan(response.data);
    };

    // Fetch lokasi
    const getKota = async () => {
            try {
                console.log(formData.provinsi)
                const response = await axios.get(`https://www.emsifa.com/api-wilayah-indonesia/api/regencies/${formData.provinsi}.json` );
                console.log(response)
                const options = response.data.map(provinsi => ({
                    value: provinsi.id,
                    label: provinsi.name,
                }));
                setSuggestedKota(options);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
    
    // Fetch lokasi
    const getLokasi = async () => {
            try {
                const response = await axios.get('https://www.emsifa.com/api-wilayah-indonesia/api/provinces.json');
                const options = response.data.map(provinsi => ({
                    value: provinsi.id,
                    label: provinsi.name,
                }));
                setSuggestedProvinsi(options);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
    
    const navigate = useNavigate();

    // const saveUser = async (e) => {
    //     e.preventDefault();
    //     try {
    //         console.log(formData)
    //         console.log(account)
    //         // Mengubah format tanggalLahir menjadi string ISO (YYYY-MM-DD)
    //         // const tanggalLahirISO = `${formData.tanggalLahir.year}-${formData.tanggalLahir.month}-${formData.tanggalLahir.day}`;
    //         // const tanggalPerkawinanISO = formData.tanggalPerkawinan
    //         //     ? `${formData.tanggalPerkawinan.year}-${formData.tanggalPerkawinan.month}-${formData.tanggalPerkawinan.day}`
    //         //     : null;

    //         const userData = {
    //             namaDepan: formData.namaDepan,
    //             namaTengah: formData.namaTengah,
    //             namaBelakang: formData.namaBelakang,
    //             namaLain: formData.namaLain,
    //             gender: formData.gender,
    //             tempatLahir: formData.tempatLahir,
    //             tanggalLahir: formData.tanggalLahir,
    //             faseHidup: formData.faseHidup,
    //             statusPerkawinan: formData.statusPerkawinan,
    //             pasangan: formData.pasangan,
    //             tanggalPerkawinan: formData.tanggalPerkawinan,
    //             nomorTelepon: formData.nomorTelepon,
    //             email: formData.email,
    //             lokasiGereja: formData.lokasiGereja,
    //             role: "jemaat", // Hardcoded role
    //             ayah: formData.ayah,
    //             ibu: formData.ibu,
    //             kerinduan: formData.kerinduan,
    //             komitmenBerjemaat: formData.komitmenBerjemaat,
    //             dateAdded: new Date(),   
    //             status: "active"
    //         };

    //         // Filter hanya properti yang memiliki nilai
    //         const filteredUserData = Object.fromEntries(
    //             Object.entries(userData).filter(([_, value]) => value !== null && value !== undefined && value !== "")
    //         );

    //         console.log(filteredUserData);
    //          // Send the user data to the backend and get the response
    //         const response = await axios.post(`${process.env.REACT_APP_API_URL}/insertPerson`, filteredUserData);

    //         let personId =''
    //         if (response.data && response.data.personId) {
    //         personId = response.data.personId;

    //         } else {
    //             // Handle cases where personId is not returned
    //             console.error("Failed to receive personId from the server.");
    //             // Optionally, add more error handling logic here
    //         }

    //         const accountData = {
    //                 UserName:account.username,
    //                 Email:account.email,
    //                 password:account.password,
    //                 role:"jemaat",
    //                 personId: personId,
    //                 dateAdded:new Date(),   
    //             };
            
    //         // Filter hanya properti yang memiliki nilai
    //         const filteredAccountData = Object.fromEntries(
    //             Object.entries(accountData).filter(([_, value]) => value !== null && value !== undefined && value !== "")
    //         );
    //         console.log(filteredAccountData)
    //         await axios.post(`${process.env.REACT_APP_API_URL}/insertUser`, filteredUserData);
    //         navigate("/");
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };
    
    const saveUser = async (e) => {
        e.preventDefault();

        // Check if password and password2 are the same
        if (account.password !== account.password2) {
            alert("Passwords do not match! Please try again.");
            return; // Exit the function if the passwords don't match
        }

        try {
            console.log(formData);
            console.log(account);

            // Prepare the user data
            const userData = {
                namaDepan: formData.namaDepan,
                namaTengah: formData.namaTengah,
                namaBelakang: formData.namaBelakang,
                namaLain: formData.namaLain,
                gender: formData.gender,
                tempatLahir: formData.tempatLahir,
                tanggalLahir: formData.tanggalLahir,
                faseHidup: formData.faseHidup,
                statusPerkawinan: formData.statusPerkawinan,
                pasangan: formData.pasangan,
                tanggalPerkawinan: formData.tanggalPerkawinan,
                nomorTelepon: formData.nomorTelepon,
                email: formData.email,
                lokasiGereja: formData.lokasiGereja,
                role: "jemaat", // Hardcoded role
                ayah: formData.ayah,
                ibu: formData.ibu,
                kerinduan: formData.kerinduan,
                komitmenBerjemaat: formData.komitmenBerjemaat,
                dateAdded: new Date(),
                status: "active"
            };

            // Filter only properties that have a value
            const filteredUserData = Object.fromEntries(
                Object.entries(userData).filter(([_, value]) => value !== null && value !== undefined && value !== "")
            );

            console.log(filteredUserData);

            // Send the user data to the backend and get the response
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/insertPerson`, filteredUserData);

            let personId = '';

            if (response.data && response.data.personId) {
                personId = response.data.personId;

                // Prepare account data once personId is available
                const accountData = {
                    UserName: account.username,
                    Email: account.email,
                    password: account.password,
                    role: "jemaat",
                    personId: personId,
                    dateAdded: new Date(),
                };

                // Filter only properties that have a value
                const filteredAccountData = Object.fromEntries(
                    Object.entries(accountData).filter(([_, value]) => value !== null && value !== undefined && value !== "")
                );

                console.log(filteredAccountData);

                // Post account data after person is inserted successfully
                await axios.post(`${process.env.REACT_APP_API_URL}/insertUser`, filteredAccountData);
                
                // Navigate to another page after successful account creation
                navigate("/");

            } else {
                // Handle cases where personId is not returned
                console.error("Failed to receive personId from the server.");
            }

        } catch (error) {
            console.log(error);
        }
    };

    const handleClick = (section) => {
        console.log(`Active Section: ${section}`); // Check if the section is changing
        setActiveSection(section);
    };


return (
    <div className="border">
        <div className="columns is-mobile mr-2 login-container">
            <div className="rectangle-container">
                <div className="column is-4 has-text-centered is-flex is-flex-direction-column is-align-items-center">
                    {/* Card */}
                    <div className="card has-text-left">
                        <div
                            className={`is-size-6 mb-4 has-text-weight-bold ${activeSection === 'personal' ? 'active-section' : ''}`}
                            onClick={() => handleClick('personal')}
                        >
                            Personal Information
                        </div>
                        <div
                            className={`is-size-6 mb-4 has-text-weight-bold ${activeSection === 'family' ? 'active-section' : ''}`}
                            onClick={() => handleClick('family')}
                        >
                            Status and Family
                        </div>
                        <div
                            className={`is-size-6 mb-4 has-text-weight-bold ${activeSection === 'contact' ? 'active-section' : ''}`}
                            onClick={() => handleClick('contact')}
                        >
                            Contact
                        </div>
                        <div
                            className={`is-size-6 mb-4 has-text-weight-bold ${activeSection === 'church' ? 'active-section' : ''}`}
                            onClick={() => handleClick('church')}
                        >
                            Church Congregation
                        </div>
                        <div
                            className={`is-size-6 mb-4 has-text-weight-bold ${activeSection === 'account' ? 'active-section' : ''}`}
                            onClick={() => handleClick('account')}
                        >
                            Account Infomation
                        </div>

                        <style jsx>{`
                            .active-section {
                                background-color: #0082FF;
                                color: white;
                                cursor: pointer;
                            }
                            .card div {
                                padding: 10px;
                            }
                        `}</style>                    
                    </div>
                </div>
                {activeSection === 'personal' ? (
                    <div className="column is-8">
                        <div className="is-size-3 mb-4 has-text-weight-bold">
                            Register
                        </div>
                        {/* Personal Information Section */}
                        <div className="is-size-3 mb-4 has-text-weight-bold" style={{ color: '#005EB8' }}>
                            Personal Information
                        </div>
                        <div className="columns">
                            <div className="column is-6">
                                <div className="is-size-6 has-text-weight-bold mb-2">
                                    Name
                                </div>
                                <label className='control'>
                                    <input
                                        type="text"
                                        className='input'
                                        name="namaDepan" // Important to add the name attribute for handleInputChange
                                        value={formData.namaDepan}
                                        onChange={handleInputChange}
                                        placeholder='First Name'
                                    />
                                </label>
                                <label className='control'>
                                    <input
                                        type="text"
                                        className='input'
                                        name="namaTengah" // Important to add the name attribute for handleInputChange
                                        value={formData.namaTengah}
                                        onChange={handleInputChange}
                                        placeholder='Middle Name'
                                    />
                                </label>
                                <label className='control'>
                                    <input
                                        type="text"
                                        className='input'
                                        name="namaBelakang" // Important to add the name attribute for handleInputChange
                                        value={formData.namaBelakang}
                                        onChange={handleInputChange}
                                        placeholder='Last Name'
                                    />
                                </label>
                                <label className='control'>
                                    <input
                                        type="text"
                                        className='input'
                                        name="namaLain" // Important to add the name attribute for handleInputChange
                                        value={formData.namaLain}
                                        onChange={handleInputChange}
                                        placeholder='Another Name'
                                    />
                                </label>
                                <div className="is-size-6 has-text-weight-bold mb-2">
                                    Gender 
                                </div>
                                <div className='checkbox-options-container'>
                                    <div className='checkbox-options'>
                                        <label className='control'>
                                            <input
                                                type="checkbox"
                                                name="gender"
                                                value="Male"
                                                checked={formData.gender === "Male"}
                                                onChange={handleInputChange}
                                                className='checkbox-input'
                                            />
                                            Male
                                        </label>
                                    </div>
                                    <div className='checkbox-options'>
                                        <label className='control'>
                                            <input
                                                type="checkbox"
                                                name="gender"
                                                value="Female"
                                                checked={formData.gender === "Female"}
                                                onChange={handleInputChange}
                                                className='checkbox-input'
                                            />
                                            Female
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="column is-6">
                                <div className="is-size-6 has-text-weight-bold mb-2">
                                    Place of Birth
                                </div>
                                <Select
                                    options={suggestedProvinsi}
                                    onChange={(selectedOption) => {
                                        setFormData({
                                            ...formData,
                                            provinsi: selectedOption ? selectedOption.value : ''
                                        });
                                        // Fetch cities based on selected province
                                        getKota(selectedOption ? selectedOption.value : '');
                                    }}
                                    placeholder='Select Province'
                                    value={suggestedProvinsi.find(option => option.value === formData.provinsi)}
                                    className='mb-2'
                                />
                                <Select
                                    options={suggestedKota}
                                    onChange={(selectedOption) => {
                                        setFormData({
                                            ...formData,
                                            tempatLahir: selectedOption ? selectedOption.label : ''
                                        });
                                    }}
                                    placeholder='Select Cities'
                                    value={suggestedKota.find(option => option.label === formData.tempatLahir)}
                                />
                                <div className="is-size-6 has-text-weight-bold mb-2">
                                    Date of Birth
                                </div>
                                <label className='control'>
                                    <input
                                        type="date"
                                        className='input'
                                        name="tanggalLahir" // Important to add the name attribute for handleInputChange
                                        value={formData.tanggalLahir}
                                        onChange={handleInputChange}
                                        placeholder='Date of Birth'
                                    />
                                </label>
                                <div className="is-size-6 has-text-weight-bold mb-2">
                                    Life Phase
                                </div>
                                <div className='checkbox-options-container'>
                                    <div className='checkbox-options'>
                                        <label className='control'>
                                            <input
                                                type="checkbox"
                                                name="faseHidup"
                                                value="Anak-anak"
                                                checked={formData.faseHidup === "Anak-anak"}
                                                onChange={handleInputChange}
                                                className='checkbox-input'
                                            />
                                            Anak-anak
                                        </label>
                                    </div>
                                    <div className='checkbox-options'>
                                        <label className='control'>
                                            <input
                                                type="checkbox"
                                                name="faseHidup"
                                                value="PraRemaja/Remaja"
                                                checked={formData.faseHidup === "PraRemaja/Remaja"}
                                                onChange={handleInputChange}
                                                className='checkbox-input'
                                            />
                                            PraRemaja/Remaja
                                        </label>
                                    </div>
                                    <div className='checkbox-options'>
                                        <label className='control'>
                                            <input
                                                type="checkbox"
                                                name="faseHidup"
                                                value="Pemuda"
                                                checked={formData.faseHidup === "Pemuda"}
                                                onChange={handleInputChange}
                                                className='checkbox-input'
                                            />
                                            Pemuda
                                        </label>
                                    </div>
                                    <div className='checkbox-options'>
                                        <label className='control'>
                                            <input
                                                type="checkbox"
                                                name="faseHidup"
                                                value="DewasaMuda"
                                                checked={formData.faseHidup === "DewasaMuda"}
                                                onChange={handleInputChange}
                                                className='checkbox-input'
                                            />
                                            DewasaMuda
                                        </label>
                                    </div>
                                    <div className='checkbox-options'>
                                        <label className='control'>
                                            <input
                                                type="checkbox"
                                                name="faseHidup"
                                                value="Berkeluarga"
                                                checked={formData.faseHidup === "Berkeluarga"}
                                                onChange={handleInputChange}
                                                className='checkbox-input'
                                            />
                                            Berkeluarga
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : activeSection === 'family' ? (
                    <div className="column is-8">
                        <div className="is-size-3 mb-4 has-text-weight-bold">
                            Register
                        </div>
                        {/* Family Section */}
                        <div className="is-size-3 mb-4 has-text-weight-bold" style={{ color: '#005EB8' }}>
                            Status and Family
                        </div>
                        <div className="columnns">
                            <div className="column is-6">
                                <div className="is-size-6 has-text-weight-bold mb-2">
                                    Martial Status
                                </div>
                                <div className='checkbox-options-container'>
                                    <div className='checkbox-options'>
                                        <label className='control'>
                                            <input
                                                type="checkbox"
                                                name="statusPerkawinan"
                                                value="BelumMenikah"
                                                checked={formData.statusPerkawinan === "BelumMenikah"}
                                                onChange={handleInputChange}
                                                className='checkbox-input'
                                            />
                                            Belum Menikah
                                        </label>
                                    </div>
                                    <div className='checkbox-options'>
                                        <label className='control'>
                                            <input
                                                type="checkbox"
                                                name="statusPerkawinan"
                                                value="SudahMenikah"
                                                checked={formData.statusPerkawinan === "SudahMenikah"}
                                                onChange={handleInputChange}
                                                className='checkbox-input'
                                            />
                                            Sudah Menikah
                                        </label>
                                    </div>
                                    <div className='checkbox-options'>
                                        <label className='control'>
                                            <input
                                                type="checkbox"
                                                name="statusPerkawinan"
                                                value="Janda/Duda"
                                                checked={formData.statusPerkawinan === "Janda/Duda"}
                                                onChange={handleInputChange}
                                                className='checkbox-input'
                                            />
                                            Janda/Duda
                                        </label>
                                    </div>
                                </div>
                                {formData.statusPerkawinan === "SudahMenikah" && (
                                    <div>
                                        <div className="is-size-6 has-text-weight-bold mb-2">
                                            Date of Marriage
                                        </div>
                                        <label className='control'>
                                            <input
                                                type="date"
                                                className='input'
                                                name="tanggalPerkawinan" // Important to add the name attribute for handleInputChange
                                                value={formData.tanggalPerkawinan}
                                                onChange={handleInputChange}
                                                placeholder='Date of Marriage'
                                            />
                                        </label>
                                    </div>
                                )}
                                <div className="is-size-6 has-text-weight-bold mb-2">
                                    Father Name
                                </div>
                                <Select
                                    options={optionAyah}
                                    onChange={(selectedOption) => {
                                        setFormData({
                                            ...formData,
                                            ayah: selectedOption ? selectedOption.value : ''
                                        });
                                    }}
                                    placeholder='Select Province'
                                    value={optionAyah.find(option => option.value === formData.ayah)}
                                    className='mb-2'
                                />
                                <div className="is-size-6 has-text-weight-bold mb-2">
                                    Mother Name
                                </div>
                                <Select
                                    options={optionIbu}
                                    onChange={(selectedOption) => {
                                        setFormData({
                                            ...formData,
                                            ibu: selectedOption ? selectedOption.value : ''
                                        });
                                    }}
                                    placeholder='Select Province'
                                    value={optionIbu.find(option => option.value === formData.ibu)}
                                    className='mb-2'
                                />
                            </div>
                        </div>
                        {/* Empty for now, add content later */}
                    </div>
                ) : activeSection === 'contact' ? (
                    <div className="column is-8">
                        <div className="is-size-3 mb-4 has-text-weight-bold">
                            Register
                        </div>
                        {/* Contact Section */}
                        <div className="is-size-3 mb-4 has-text-weight-bold" style={{ color: '#005EB8' }}>
                            Contact
                        </div>
                        <div className="columns">
                            <div className="column is-6">
                                <div className="is-size-6 has-text-weight-bold mb-2">
                                    Phone Number
                                </div>
                                <label className='control'>
                                    <input
                                        type="number"
                                        className='input'
                                        name="nomorTelepon" // Important to add the name attribute for handleInputChange
                                        value={formData.nomorTelepon}
                                        onChange={handleInputChange}
                                        placeholder='Phone Number'
                                    />
                                </label>
                                <div className="is-size-6 has-text-weight-bold mb-2">
                                    Email
                                </div>
                                <label className='control'>
                                    <input
                                        type="text"
                                        className='input'
                                        name="email" // Important to add the name attribute for handleInputChange
                                        value={formData.email}
                                        onChange={handleInputChange}
                                        placeholder='Email'
                                    />
                                </label>
                            </div>
                        </div>
                    </div>
                ) : activeSection === 'church' ? (
                    <div className="column is-8">
                        <div className="is-size-3 mb-4 has-text-weight-bold">
                            Register
                        </div>
                        {/* Church Congregation Section */}
                        <div className="is-size-3 mb-4 has-text-weight-bold" style={{ color: '#005EB8' }}>
                            Church Congregation
                        </div>
                        <div className="columns">
                            <div className="column is-6">
                                <div className="is-size-6 has-text-weight-bold mb-2">
                                    Local Church
                                </div>
                                <div className='checkbox-options-container'>
                                    <div className='checkbox-options'>
                                        <label className='control'>
                                            <input
                                                type="checkbox"
                                                name="lokasiGereja"
                                                value="SurabayaBarat"
                                                checked={formData.lokasiGereja === "SurabayaBarat"}
                                                onChange={handleInputChange}
                                                className='checkbox-input'
                                            />
                                            Surabaya Barat
                                        </label>
                                    </div>
                                    <div className='checkbox-options'>
                                        <label className='control'>
                                            <input
                                                type="checkbox"
                                                name="lokasiGereja"
                                                value="SurabayaTimur"
                                                checked={formData.lokasiGereja === "SurabayaTimur"}
                                                onChange={handleInputChange}
                                                className='checkbox-input'
                                            />
                                            Surabaya Timur
                                        </label>
                                    </div>
                                </div>
                                <div className="is-size-6 has-text-weight-bold mb-2">
                                    Longing
                                </div>
                                <label className='control'>
                                    <input
                                        type="text"
                                        className='input'
                                        name="kerinduan" // Important to add the name attribute for handleInputChange
                                        value={formData.kerinduan}
                                        onChange={handleInputChange}
                                        placeholder='Longing'
                                    />
                                </label>
                                <div className="is-size-6 has-text-weight-bold mb-2">
                                    Congregational Commitment
                                </div>
                                <label className='control'>
                                    <textarea
                                        className='input large-textarea'
                                        name="komitmenBerjemaat" // Important to add the name attribute for handleInputChange
                                        value={formData.komitmenBerjemaat}
                                        onChange={handleInputChange}
                                        placeholder='Congregational Commitment'
                                        rows="6" // Number of visible rows
                                    />
                                </label>
                            </div>
                        </div>
                    </div>
                ) : activeSection === 'account' ? (
                    <div className="column is-8">
                        <div className="is-size-3 mb-4 has-text-weight-bold">
                            Register
                        </div>
                        {/* Church Congregation Section */}
                        <div className="is-size-3 mb-4 has-text-weight-bold" style={{ color: '#005EB8' }}>
                            Account
                        </div>
                        <div className="columns">
                            <div className="column is-6">
                                <div className="is-size-6 has-text-weight-bold mb-2">
                                    Username
                                </div>
                                <label className='control'>
                                    <input
                                        type="text"
                                        className='input'
                                        name="username" // Important to add the name attribute for handleInputChange
                                        value={account.username}
                                        onChange={handleAccountChange}
                                        placeholder='Username'
                                    />
                                </label>
                                <div className="is-size-6 has-text-weight-bold mb-2">
                                    Password
                                </div>
                                <label className='control'>
                                    <input
                                        type="password"
                                        className='input'
                                        name="password" // Important to add the name attribute for handleInputChange
                                        value={account.password}
                                        onChange={handleAccountChange}
                                        placeholder='password'
                                    />
                                </label>
                                <div className="is-size-6 has-text-weight-bold mb-2">
                                    Password 
                                </div>
                                <label className='control'>
                                    <input
                                        type="password"
                                        className='input'
                                        name="password2" // Important to add the name attribute for handleInputChange
                                        value={account.password2}
                                        onChange={handleAccountChange}
                                        placeholder='password2'
                                    />
                                </label>
                                <button
                                    className='button mt-2'
                                    style={{ backgroundColor: '#005EB8', width: '150px', color: 'white' }}
                                    onClick={(e) => {
                                        alert('Button clicked');
                                        saveUser(e);
                                    }}
                                >
                                    Save
                                </button>

                            </div>
                            
                        </div>
                    </div>
                ):
                null}
                
            </div>
        </div>
    </div>
)
}

export default UserRegisterDataJemaat;
