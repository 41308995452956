import React, { useEffect, useState } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import axios from 'axios';
import $ from 'jquery';
import 'datatables.net';
import "../style/tableStyle.css";

const LifegroupActivityAttendanceDetail = () => {
    const [users, setUsers] = useState([]);
    const [dataFetched, setDataFetched] = useState(false);
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        getUsers();
    }, []);

    useEffect(() => {
        if (dataFetched) {
            $('#dataTable').DataTable(); // Initialize DataTables
        }
    }, [dataFetched]);

    const getUsers = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewAttendanceListByEventId/${id}`);
            console.log(response);
            setUsers(response.data);
            setDataFetched(true); // Set flag to indicate data fetching is complete
        } catch (error) {
            console.error(error);
        }
    };

    const deleteUser = async (id) => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/deleteTag/${id}`);
            navigate("/LifegroupActivityAttendanceDetail");
        } catch (error) {
            console.error(error);
        }
    };

    const handleButtonClick = () => {
        // ganti ke update attendance
        if (users.length > 0) {
            navigate(`/ActivityLGAttendanceUpdate/${id}`);
        } else {
            navigate(`/ActivityLGAttendance/${id}`);
        }
    };

    return (
        <div className="columns">
            <div className="column">
                <div className="columns">
                    <div className="column is-10">
                        <h1>Presensi</h1>
                    </div>
                    <div className="column">
                        <div className="button" onClick={handleButtonClick}>Add/ Edit Peresensi</div>
                    </div>
                </div>
                <table id="dataTable" className="table is-striped is-fullwidth mt-5">
                    <thead>
                        <tr>
                            <th>Person Name</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map(user => (
                            <tr key={user._id}>
                                <td>{user.namaDepan}</td>
                                <td>
                                    <input type="checkbox" checked={user.kehadiran} disabled className="checkbox custom-checkbox" />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default LifegroupActivityAttendanceDetail;
