import React,{useState, useEffect} from 'react'
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select'


const AddUser = () => {
    const [namaDepan, setName] = useState("");
    const [namaTengah, setNamaTengah] = useState("");
    const [namaBelakang, setNamaBelakang] = useState("");
    const [namaLain, setNamaLain] = useState("");
    const [gender, setGender] = useState("");
    const [tanggalLahir, setTanggalLahir] = useState("");
    const [tempatLahir, setTempatLahir] = useState("");
    const [faseHidup, setFaseHidup] = useState("");
    const [statusPerkawinan, setStatusPerkawinan] = useState("");
    const [pasangan, setPasangan] = useState("");
    const [tanggalPerkawinan, setTanggalPerkawinan] = useState("");
    const [nomorTelepon, setNomorTelepon] = useState("");
    const [email, setEmail] = useState("");
    const [lokasiGereja, setLokasiGereja] = useState("");
    const [role, setRole] = useState("");
    const [orangTua, setOrangTua] = useState("");
    const [komitmenPelayanan, setKomitmenPelayanan] = useState("");
    const [komitmenBerjemaat, setKomitmenBerjemaat] = useState("");
    const [ayah, setAyah] = useState("");
    const [ibu, setIbu] = useState("");
    const [kerinduan, setKerinduan] = useState("");

    const navigate = useNavigate();
    const { id } = useParams();

    // Untuk Sugest 
    const [suggestedPasangan, setSuggestedPasangan] = useState([]);
    const [suggestedProvinsi, setSuggestedProvinsi] = useState([]);
    const [suggestedKota, setSuggestedKota] = useState([]);
    const [provinsi, setProvinsi] = useState("");

      // Untuk Sugest OrangTua
    const [optionAyah, setoptionAyah] = useState([]);
    const [selectedAyah, setselectedAyah] = useState(null);
    const [optionIbu, setoptionIbu] = useState([]);
    const [selectedIbu, setselectedIbu] = useState(null);

    const [Father, setFather] = useState({ namaDepan: '' }); // Default kosong
    const [Mother, setMother] = useState({ namaDepan: '' }); // Default kosong

    let jemaatList = "";

    // Fetch nama jemaat yang ada untuk suggested pasangan
    const getJemaat = async () => {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewPerson`);
        setSuggestedPasangan(response.data);
        jemaatList = response.data;
        // console.log(jemaatList);
    };

    // Fetch lokasi
    const getKota = async () => {
        try {
            const response = await axios.get(`https://www.emsifa.com/api-wilayah-indonesia/api/regencies/${provinsi}.json`);
            console.log(response.data)
            setSuggestedKota(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    // Fetch lokasi
    const getLokasi = async () => {
        try {
            const response = await axios.get('https://www.emsifa.com/api-wilayah-indonesia/api/provinces.json');
            console.log(response.data)
            setSuggestedProvinsi(response.data);
            console.log(provinsi)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
     // Ayah
    // select Ayah
    useEffect(() => {
        const fetchDJOptions = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewPerson`);

            // Memisahkana male dan female
            const males = response.data.filter(person => person.gender === 'Male');

            const options = males.map((dj) => ({
            value: dj.personId,
            label: `${dj.namaDepan} ${dj.namaTengah} (ID: ${dj.personId})`,
            }));
            console.log(options)
            setoptionAyah(options);
        } catch (error) {
            console.error('Error fetching discipleship journey types:', error);
        }
        };

        fetchDJOptions();
    }, []);
    // Handle select Ibu
    const handleAyahChange = (selectedOption) => {
        setselectedAyah(selectedOption);
        setAyah(selectedOption.value);
    };

    // Ibu
    // select Ayah
    useEffect(() => {
        const fetchDJOptions = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewPerson`);

                // Memisahkana male dan female
                const males = response.data.filter(person => person.gender === 'Female');

                const options = males.map((dj) => ({
                    value: dj.personId,
                    label: `${dj.namaDepan} ${dj.namaTengah} (ID: ${dj.personId})`,
                }));
                console.log(options)
                setoptionIbu(options);
            } catch (error) {
                console.error('Error fetching discipleship journey types:', error);
            }
        };

        fetchDJOptions();
    }, []);
    // Handle select Ibu
    const handleIbuChange = (selectedOption) => {
        setselectedIbu(selectedOption);
        setIbu(selectedOption.value);
    };
    //Use Effect
    useEffect(() => {
        getLokasi();
        getKota();
    }, [provinsi]);

    useEffect(()=>{
        getUserById();
        getJemaat();
    }, []);

    const getUserById= async () => {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewPersonById/${id}`);
        const response2 = await axios.get(`${process.env.REACT_APP_API_URL}/personFamily/${id}`);
        console.log(response2);
        setFather(response2.data.father || { namaDepan: '' }); // Jika tidak ada data, atur default kosong
        setMother(response2.data.mother || { namaDepan: '' }); // Jika tidak ada data, atur default kosong
        console.log(response)
        setName(response.data.namaDepan);
        setNamaTengah(response.data.namaTengah);
        setNamaBelakang(response.data.namaBelakang);
        setNamaLain(response.data.namaLain);
        setGender(response.data.gender);
        setTanggalLahir(response.data.tanggalLahir);
        setTempatLahir(response.data.tempatLahir);
        setFaseHidup(response.data.faseHidup);
        setStatusPerkawinan(response.data.statusPerkawinan);
        setPasangan(response.data.pasangan);
        setTanggalPerkawinan(response.data.tanggalPerkawinan);
        setNomorTelepon(response.data.nomorTelepon);
        setEmail(response.data.email);
        setLokasiGereja(response.data.lokasiGereja);
        setRole(response.data.role);
        setOrangTua(response.data.orangTua);
        setKomitmenPelayanan(response.data.komitmenPelayanan);
        setKomitmenBerjemaat(response.data.komitmenBerjemaat);
        setAyah(response.data.ayah)
        setIbu(response.data.ibu)
        setKerinduan(response.data.kerinduan)
        console.log(tempatLahir)

    };

    const updateUser = async(e) => {
        e.preventDefault();
        try {
            await axios.patch(`${process.env.REACT_APP_API_URL}/updatePerson/${id}`,{
                namaDepan,
                namaTengah,
                namaBelakang,
                namaLain,
                gender,
                tanggalLahir,
                tempatLahir,
                faseHidup,
                statusPerkawinan,
                pasangan,
                tanggalPerkawinan,
                nomorTelepon,
                email,
                lokasiGereja,
                role,
                orangTua,
                komitmenPelayanan,
                komitmenBerjemaat,
                ayah,
                ibu,
            });
            navigate("/")
        } catch (error) {
            console.log(error);
        }
    };

return (
<div className="border">
    <div className="columns is-mobile mr-2">
        <div className="column">
            <div className='is-size-4 mb-3 has-text-weight-bold'>Edit Person</div>
            <hr />
            <div className='is-size-4 mb-3 has-text-weight-semibold'>Personal Data</div>
            <hr />
            {/* Nama */}
            <div className="columns">
                <div className="column is-2">
                    <div className=' is-size-5 mb-3 has-text-weight-semibold'>Name</div>
                </div>
                <div className="column is-9">
                    {/* nama depan */}
                    <label className='control'>
                        <div className=' is-size-6 mb-2 has-text-weight-semibold'>First Name</div>
                        <input type="text" className='input' value={namaDepan}
                            onChange={(e) => setName(e.target.value)}
                            placeholder='namaDepan' />
                    </label>
                    {/* Nama tengah */}
                    <label className='control'>
                        <div className=' is-size-6 mb-2 has-text-weight-semibold'>Middle Name</div>
                        <input type="text" className='input' value={namaTengah}
                            onChange={(e) => setNamaTengah(e.target.value)}
                            placeholder='namaTengah' />
                    </label>
                    {/* Nama belakang */}
                    <label className='control'>
                        <div className=' is-size-6 mb-2 has-text-weight-semibold'>Last Name</div>
                        <input type="text" className='input' value={namaBelakang}
                            onChange={(e) => setNamaBelakang(e.target.value)}
                            placeholder='Nama Belakang' />
                    </label>
                    {/* Nama Lain */}
                    <label className='control'>
                        <div className=' is-size-6 mb-2 has-text-weight-semibold'>Another Name</div>
                        <input type="text" className='input' value={namaLain}
                            onChange={(e) => setNamaLain(e.target.value)}
                            placeholder='Nama Lain' />
                    </label>
                </div>
            </div>
            {/* Jenis kelamin */}
            <div className="columns">
                <div className="column is-2">
                    <div className=' is-size-5 mb-3 has-text-weight-semibold'>Gender</div>
                </div>
                <div className="column is-9">
                    <div className="control mb-2 radio-container">
                        <label className="radio is-large">
                            <input
                                type="radio"
                                value={gender === "Male" ? "" : "Male"}
                                checked={gender === "Male"}
                                onChange={(e) => setGender(e.target.value)}
                            />
                            <span className="radio-text">Male</span>
                        </label>
                        <label className="radio is-large">
                            <input
                                type="radio"
                                value={gender === "Female" ? "" : "Female"}
                                checked={gender === "Female"}
                                onChange={(e) => setGender(e.target.value)}
                            />
                            <span className="radio-text">Female</span>
                        </label>
                    </div>
                </div>
            </div>
            {/* Tempat Lahir */}
            <div className="columns">
                <div className="column is-2">
                    <div className=' is-size-5 mb-3 has-text-weight-semibold'>Place of Birth</div>
                </div>
                <div className="column is-5">
                    <label className='control mb-2'>
                        <select className='input' value={provinsi} onChange={(e) => setProvinsi(e.target.value)}>
                            <option value="">Nama Provinsi</option>
                            {/* Check if provinsiList is an array before mapping over it */}
                            {Array.isArray(suggestedProvinsi) && suggestedProvinsi.map((data) => (
                                <option key={data.id} value={data.id}>
                                    {data.name} {/* Menggunakan nilai jemaat.namaDepan sebagai teks opsi */}
                                </option>
                            ))}
                        </select>
                    </label>
                </div>
                <div className="column is-5">
                    <label className='control mb-2'>
                        <select className='input' value={tempatLahir} onChange={(e) => setTempatLahir(e.target.value)}>
                            <option value="">{tempatLahir}</option>
                            {/* Check if provinsiList is an array before mapping over it */}
                            {Array.isArray(suggestedKota) && suggestedKota.map((data) => (
                                <option key={data.id} value={data.name}>
                                    {data.name} {/* Menggunakan nilai jemaat.namaDepan sebagai teks opsi */}
                                </option>
                            ))}
                        </select>
                    </label>
                </div>
            </div>
            {/* Tanggal Lahir */}
            <div className="columns">
                <div className="column is-2">
                    <div className=' is-size-5 mb-3 has-text-weight-semibold'>Date of Birth</div>
                </div>
                <div className="column is-9">
                    <label className='control'>
                        <div className="field is-horizontal">
                            <div className="field-body">
                                {/* Field Hari */}
                                <div className="field">
                                    <div className="control">
                                            <input type="number" className='input' value={tanggalLahir.day}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                if (value <= 31 && value >= 1) {
                                                    setTanggalLahir({ ...tanggalLahir, day: value });
                                                }
                                            }}
                                            placeholder='Day' />
                                    </div>
                                </div>
                                {/* Field Bulan */}
                                <div className="field">
                                    <div className="control">
                                        <input type="number" className='input' value={tanggalLahir.month}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                if (value <= 12 && value >= 1) {
                                                    setTanggalLahir({ ...tanggalLahir, month: value });
                                                }
                                            }}
                                            placeholder='Month' />
                                    </div>
                                </div>
                                {/* Field Tahun */}
                                <div className="field">
                                    <div className="control">
                                        <input type="number" className='input' value={tanggalLahir.year || ''}
                                            onChange={(e) => setTanggalLahir({ ...tanggalLahir, year: e.target.value })}
                                            placeholder= 'Year' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </label>
                </div>
            </div>
            {/* Fase Hidup */}
            <div className="columns">
                <div className="column is-2">
                    <div className=' is-size-5 mb-3 has-text-weight-semibold'>Life Phase</div>
                </div>
                <div className="column is-9">
                    <div className="control mb-2 radio container">
                        <label className="radio is-large"
                            title=" 0 - 10 Tahun">
                            <input
                                type="radio"
                                value={faseHidup === "Anak-anak" ? "" : "Anak-anak"}
                                checked={faseHidup === "Anak-anak"}
                                onChange={(e) => setFaseHidup(e.target.value)}
                            />
                            <span className="radio-text">Anak-anak</span>
                        </label>
                        <label className="radio is-large"
                            title=" 10 - 12 Tahun">
                            <input
                                type="radio"
                                value={faseHidup === "Pra-remaja/Remaja" ? "" : "Pra-remaja/Remaja"}
                                checked={faseHidup === "Pra-remaja/Remaja"}
                                onChange={(e) => setFaseHidup(e.target.value)}
                            />
                            <span className="radio-text">PraRemaja/Remaja</span>
                        </label>
                        <label className="radio is-large"
                            title=" SMA dan Kuliah ">
                            <input
                                type="radio"
                                value={faseHidup === "Pemuda" ? "" : "Pemuda"}
                                checked={faseHidup === "Pemuda"}
                                onChange={(e) => setFaseHidup(e.target.value)}
                            />
                            <span className="radio-text">Pemuda</span>
                        </label>
                        <label className="radio is-large"
                            title=" Profesional Muda">
                            <input
                                type="radio"
                                value={faseHidup === "Dewasa Muda" ? "" : "Dewasa Muda"}
                                checked={faseHidup === "Dewasa Muda"}
                                onChange={(e) => setFaseHidup(e.target.value)}
                            />
                            <span className="radio-text">Dewasa Muda</span>
                        </label>
                        <label className="radio is-large"
                            title=" Family Life">
                            <input
                                type="radio"
                                value={faseHidup === "Berkeluarga" ? "" : "Berkeluarga"}
                                checked={faseHidup === "Berkeluarga"}
                                onChange={(e) => setFaseHidup(e.target.value)}
                            />
                            <span className="radio-text">Berkeluarga</span>
                        </label>
                        {/* Decease untuk org  yang sudah meningggal , role == admin */}
                    </div>
                </div>
            </div>
            <div className='is-size-4 mb-3 has-text-weight-semibold'>Status and Family</div>
            <hr />
            {/* Status Hidup */}
            <div className="columns">
                <div className="column is-2">
                    <div className=' is-size-5 mb-3 has-text-weight-semibold'>Status Perkawinan</div>
                </div>
                <div className="column is-9">
                    <div className="control mb-2">
                        <label className="radio">
                            <input
                                type="radio"
                                value={statusPerkawinan === "Belum Menikah" ? "" : "Belum Menikah"}
                                checked={statusPerkawinan === "Belum Menikah"}
                                onChange={(e) => setStatusPerkawinan(e.target.value)}
                            />
                            <span className="radio-text">Belum Menikah</span>
                        </label>
                        <label className="radio">
                            <input
                                type="radio"
                                value={statusPerkawinan === "Menikah" ? "" : "Menikah"}
                                checked={statusPerkawinan === "Menikah"}
                                onChange={(e) => setStatusPerkawinan(e.target.value)}
                            />
                            <span className="radio-text">Menikah</span>
                        </label>
                        <label className="radio">
                            <input
                                type="radio"
                                value={statusPerkawinan === "Janda/Duda" ? "" : "Janda/Duda"}
                                checked={statusPerkawinan === "Janda/Duda"}
                                onChange={(e) => setStatusPerkawinan(e.target.value)}
                            />
                            <span className="radio-text">Janda / duda </span>
                        </label>
                    </div>
                </div>
            </div>
            {/* Data pernikahan */}
            <label className={`control ${statusPerkawinan != "Menikah" ? 'hidden' : ''}`}>
                {/* tanggal perkawinan */}
                <div className="columns">
                    <div className="column is-2">
                        <div className=' is-size-5 mb-3 has-text-weight-semibold'>Marriage Date</div>
                    </div>
                    <div className="column is-9">
                        {/* Tanggal Perkawinan */}
                        <label className='control'>
                            <input type="date" className='input' value={tanggalPerkawinan}
                                onChange={(e) => setTanggalPerkawinan(e.target.value)}
                                placeholder='Tanggal Perkawinan'
                            />
                        </label>
                    </div>
                </div>
                {/* nama pasangan */}
                <div className="columns">
                    <div className="column is-2">
                        <div className=' is-size-5 mb-3 has-text-weight-semibold'>Partner Name</div>
                    </div>
                    <div className="column is-9">
                        {/* Nama Pasangan */}
                        <label className='control mb-2'>
                            <select className='input' value={pasangan} onChange={(e) => setPasangan(e.target.value)}>
                                <option value="">Nama Pasangan</option>
                                {/* Check if jemaatList is an array before mapping over it */}
                                {Array.isArray(suggestedPasangan) && suggestedPasangan.map((jemaat) => (
                                    <option key={jemaat.personId} value={jemaat.personId}>
                                        {jemaat.namaDepan} {/* Menggunakan nilai jemaat.namaDepan sebagai teks opsi */}
                                    </option>
                                ))}
                            </select>
                        </label>
                    </div>
                </div>
            </label>
            {/* Nama Orang Tua */}
            {/* orang tua */}
                    <div className="columns">
                        <div className="column is-2">
                            <div className=' is-size-5 mb-3 has-text-weight-semibold'>Father Name</div>
                        </div>
                        <div className="column is-3">
                            <div className="control">
                                <Select
                                    placeholder={Father.namaDepan + Father.namaTengah}
                                    value={selectedAyah}
                                    options={optionAyah}
                                    onChange={handleAyahChange}
                                />
                        </div>
                    </div>
                    </div>
                    <div className="columns">
                        <div className="column is-2">
                            <div className=' is-size-5 mb-3 has-text-weight-semibold'>Mother Name</div>
                        </div>
                        <div className="column is-3">
                            <Select
                                placeholder={Mother.namaDepan + Mother.namaTengah}
                                value={selectedIbu}
                                options={optionIbu}
                                onChange={handleIbuChange}
                            />
                        </div>
                    </div>
            {/* Kontak */}
            <div className='is-size-4 mb-3 has-text-weight-semibold'> Contact </div>
            <hr />
            {/* Nomor telepon */}
            <div className="columns">
                <div className="column is-2">
                    <div className=' is-size-5 mb-3 has-text-weight-semibold'>Phone Number</div>
                </div>
                <div className="column is-9">
                    <label className='control '>
                        <input
                            type="text"
                            className='input'
                            value={nomorTelepon}
                            onChange={(e) => {
                                const value = e.target.value;
                                // Menggunakan regular expression untuk memeriksa apakah input adalah nomor telepon yang valid
                                if (/^\d{0,13}$/.test(value)) {
                                    setNomorTelepon(value); // Jika valid, memperbarui nilai nomorTelepon
                                }
                            }}
                            placeholder='Nomor Telepon'
                        />
                    </label>
                </div>
            </div>
            {/* Email */}
            <div className="columns">
                <div className="column is-2">
                    <div className=' is-size-5 mb-3 has-text-weight-semibold'> Email </div>
                </div>
                <div className="column is-9">
                    <label className='control '>
                        <input type="text" className='input' value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder='Email'
                        />
                    </label>
                </div>
            </div>
            {/* Infromasi Jemaat */}
            <div className='is-size-4 mb-3 has-text-weight-semibold'>Church Congregation Information:</div>
            <hr />
            {/* Gereja Lokal */}
            <div className="columns">
                <div className="column is-2">
                    <div className=' is-size-5 mb-3 has-text-weight-semibold'> Local Church </div>
                </div>
                <div className="column is-9">
                    <div className="control mb-2">
                        <label className="radio">
                            <input
                                type="radio"
                                value={lokasiGereja === "Surabaya Barat" ? "" : "Surabaya Barat"}
                                checked={lokasiGereja === "Surabaya Barat"}
                                onChange={(e) => setLokasiGereja(e.target.value)}
                            />
                            <span className="radio-text">Surabaya Barat </span>
                        </label>
                        <label className="radio">
                            <input
                                type="radio"
                                value={lokasiGereja === "Surabaya Timur" ? "" : "Surabaya Timur"}
                                checked={lokasiGereja === "Surabaya Timur"}
                                onChange={(e) => setLokasiGereja(e.target.value)}
                            />
                            <span className="radio-text">Surabaya Timur </span>
                        </label>
                    </div>
                </div>
            </div>
            {/* kerinduan */}
            <div className="columns">
                <div className="column is-2">
                    <div className=' is-size-5 mb-3 has-text-weight-semibold'> Longing </div>
                </div>
                <div className="column is-9">
                    <label className='control mb-2'>
                        <input type="text" className='input' value={kerinduan}
                            onChange={(e) => setKerinduan(e.target.value)}
                            placeholder='Kerinduan'
                        />
                    </label>
                </div>
            </div>
            {/* komitmen berjemaat */}
            <div className="columns">
                <div className="column is-2">
                    <div className=' is-size-5 mb-3 has-text-weight-semibold'> Congregational Commitment </div>
                </div>
                <div className="column is-9">
                    <textarea
                        className='textarea'
                        value={komitmenBerjemaat}
                        onChange={(e) => setKomitmenBerjemaat(e.target.value)}
                        placeholder='Komitmen Berjemaat'
                    />
                </div>
            </div>
            {/* Buttton Save */}
            <div className="control">
                <button className='button mt-2 is-success' onClick={updateUser} >Save</button>
            </div>
        </div>
    </div>
</div>
)
}
export default AddUser