import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { useTable, useSortBy, usePagination } from 'react-table';
import "../style/tableStyle.css";

const DiscipleshipJourneyListClass = () => {
    const [DiscipleshipJourneys, setDiscipleshipJourneys] = useState([]);
    const [DiscipleshipJourneysType, setDiscipleshipJourneysType] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const [selectedEventType, setSelectedEventType] = useState('');
    const [globalFilter, setGlobalFilter] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        getDiscipleshipJourneys();
        getDiscipleshipJourneysType(); 
    }, []);

    const getDiscipleshipJourneys = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewDicsipleshipJourneyEventList`);
            console.log(response);
            setDiscipleshipJourneys(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    const getDiscipleshipJourneysType = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewDiscipleshipJourneyType`);
            console.log(response);
            setDiscipleshipJourneysType(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    const updateDiscipleshipJourney = async (id) => {
        try {
            await axios.patch(`${process.env.REACT_APP_API_URL}/updateDiscipleshipJourney/${id}`, {
                role: "deactive",
            });
            navigate("/");
        } catch (error) {
            console.log(error);
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        if (isNaN(date)) {
            console.error('Invalid date string:', dateString);
            return 'Invalid date';
        }

        return new Intl.DateTimeFormat('en-GB', {
            weekday: 'short',   // Short weekday name, e.g., "Mon"
            day: 'numeric',     // Numeric day, e.g., "3"
            month: 'short',      // Short month name, e.g., "Jan"
            year: 'numeric'      // Numeric year, e.g., "2024"
        }).format(date);
    };

    // Modal
    const [isModalActive, setIsModalActive] = useState(false);
    const [selectedJourneyId, setSelectedJourneyId] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState('mulai');

    const openModal = (id) => {
        setSelectedJourneyId(id);
        setIsModalActive(true);
    };

    const closeModal = () => {
        setIsModalActive(false);
        setSelectedJourneyId(null);
        setSelectedStatus('mulai');
    };

    //  Update
    const handleStatusUpdate = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewEvent/${selectedJourneyId}`);
            await axios.patch(`${process.env.REACT_APP_API_URL}/updateEvent/${selectedJourneyId}`, {
                eventName: response.data.eventName,
                eventDate: response.data.eventDate,
                eventTimeIn: response.data.eventTimeIn,
                eventTimeOut: response.data.eventTimeOut,
                eventSetup: response.data.eventSetup,
                eventCapacity: response.data.eventCapacity,
                eventLokasi: response.data.eventLokasi,
                seriesTitle: response.data.seriesTitle,
                fasilitator: response.data.fasilitator,
                organizer: response.data.organizer,
                sermonTitle: response.data.sermonTitle,
                preacher: response.data.preacher,
                LifeGroupById: response.data.LifeGroupById,
                eventType: response.data.eventType,
                discipleshipJourneyTypeId: response.data.discipleshipJourneyTypeId,
                createdAt: response.data.createdAt,
                status: selectedStatus,
            });
            navigate("/DiscipleshipJourneyClassList");
        } catch (error) {
            console.log(error);
        }
        closeModal();
    };

    const columns = useMemo(
        () => [
            {
                Header: 'Discipleship Journey Name',
                accessor: 'eventName',
                Cell: ({ row }) => (
                    <Link to={`/eventList/EventDetail/${row.original.eventId}`} style={{ marginRight: '10px' }}>
                        {row.original.eventName}
                    </Link>
                ),
            },
            {
                Header: 'Date',
                accessor: 'eventDate',
                Cell: ({ value }) => (value ? formatDate(value) : <span style={{ fontStyle: 'italic' }}>Tidak ada data</span>),
            },
            {
                Header: 'Disciplesip Journey Type',
                accessor: 'Djname',
                Cell: ({ value }) => value || <span style={{ fontStyle: 'italic' }}>Tidak ada data</span>,
            },
            {
                Header: 'Status',
                accessor: 'status',
                Cell: ({ value }) => {
                    let statusStyle = {
                        padding: '4px 40px',
                        borderRadius: '10px',
                        color: 'white',
                        textAlign: 'center',
                    };
                    if (value === 'mulai') {
                        statusStyle = { ...statusStyle, backgroundColor: '#FF0000' };
                    } else if (value === 'selesai') {
                        statusStyle = { ...statusStyle, backgroundColor: '#00D1FF' };
                    } else if (value === 'active') {
                        statusStyle = { ...statusStyle, backgroundColor: '#FFC700' };
                    }
                    return (
                        <span style={statusStyle}>
                            {value || <span style={{ fontStyle: 'italic' }}>Tidak ada data</span>}
                        </span>
                    );
                },
            },
            {
                Header: 'Action',
                accessor: 'eventId',
                Cell: ({ row }) => (
                    <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                        <button className="button is-primary is-small ml-2" onClick={() => openModal(row.original.eventId)}>Change Status</button>
                        {row.original.status === 'selesai' ? (
                            <Link to={`/ClassResultView/${row.original.eventId}`} className="button is-info is-small ml-2" style={{ marginRight: '10px' }}>Class Result</Link>
                        ) : (
                            <button className="button is-info is-small ml-2" disabled onClick={() => alert("Event status must be 'selesai' to view Class Result.")}>Class Result</button>
                        )}
                        <button className="button is-danger is-small" onClick={() => updateDiscipleshipJourney(row.original.eventId)}>Delete</button>
                    </div>
                ),
            },
        ],
        []
    );

    const data = useMemo(() => {
        let filteredData = DiscipleshipJourneys;

        if (selectedEventType) {
            console.log(selectedEventType)
            filteredData = filteredData.filter(item => item.Djname === selectedEventType);
        }

        if (globalFilter) {
            filteredData = filteredData.filter(item => item.eventName.toLowerCase().includes(globalFilter.toLowerCase()));
        }

        return filteredData;
    }, [DiscipleshipJourneys, selectedEventType, globalFilter]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        previousPage,
        nextPage,
        canPreviousPage,
        canNextPage,
        pageOptions,
        state: { pageIndex },
    } = useTable(
        { columns, data, initialState: { pageIndex: 0, pageSize } },
        useSortBy,
        usePagination
    );

    return (
        <div className="columns">
            <div className="column">
                <div className="columns mt-2">
                    <div className="column is-7">
                        <div className="custom-button" onClick={() => navigate('/addDiscipleshipJourneyClass')}>Add DiscipleshipJourney Class</div>
                    </div>
                    <div className="column is-flex is-justify-content-flex-end">
                        <div className="field is-grouped">
                            <div className="control">
                                <select
                                    value={selectedEventType}
                                    onChange={(e) => setSelectedEventType(e.target.value)}
                                    className="custom-select"
                                >
                                <option value="">All Discipleship Journey Type</option>
                                    {DiscipleshipJourneysType.map(type => (
                                        <option key={type.discipleshipJourneyTypeName} value={type.discipleshipJourneyTypeName}>
                                            {type.discipleshipJourneyTypeName}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="control">
                                <input
                                    className="custom-input"
                                    type="text"
                                    value={globalFilter || ''}
                                    onChange={e => setGlobalFilter(e.target.value)}
                                    placeholder="Search"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {/* Page size selector */}
                <div className="field is-grouped is-grouped-multiline" style={{ display: 'flex', alignItems: 'center' }}>
                    <div className="control" style={{ marginRight: '10px' }}>
                        <div className="select">
                            <select
                                value={pageSize}
                                onChange={(e) => setPageSize(Number(e.target.value))}
                            >
                                {[10, 25, 50, 100].map(size => (
                                    <option key={size} value={size}>
                                        {size}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <label className="label" style={{ textAlign: 'center' }}>entries per page</label>
                </div>
                {/* Table */}
                <div className="table-container">
                    <table {...getTableProps()} className="table mt-5">
                        <thead>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                            {column.render('Header')}
                                            <span>
                                                {column.isSorted
                                                    ? column.isSortedDesc
                                                        ? ' 🔽'
                                                        : ' 🔼'
                                                    : ''}
                                            </span>
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map(row => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map(cell => (
                                            <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                        ))}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                {/* Pagination */}
                <div className="pagination" style={{ display: 'flex', alignItems: 'center' }}>
                    {/* Left-aligned: Showing X to Y rows */}
                    <div style={{ flex: '1', textAlign: 'left' }}>
                        <span>
                            <strong>
                                Showing {page.length ? page[0].index + 1 : 0} to {page.length ? page[0].index + page.length : 0} 
                            </strong>
                        </span>
                    </div> 
                    {/* Center-aligned: Previous, Page Number, Next */}
                    <div style={{ display: 'flex', alignItems: 'center', flex: '1', justifyContent: 'center' }}>
                        <button className='button' onClick={() => previousPage()} disabled={!canPreviousPage}>
                            Previous
                        </button>
                        <span style={{ margin: '0 10px' }}>
                            <button className='custom-button'>
                                {pageIndex + 1}
                            </button>
                        </span>
                        <button className='button' onClick={() => nextPage()} disabled={!canNextPage}>
                            Next
                        </button>
                    </div>
                    {/* Right-aligned: Page X of Y */}
                    <div style={{ flex: '1', textAlign: 'right' }}>
                        <span>
                            <strong>
                                Page: {pageIndex + 1} of {pageOptions.length}
                            </strong>
                        </span>
                    </div>
                </div>
            </div>
            {/* Modal */}
            <div className={`modal ${isModalActive ? 'is-active' : ''}`}>
                <div className="modal-background" onClick={closeModal}></div>
                <div className="modal-content">
                    <div className="box">
                        <p>Select the status for this class:</p>
                        <div className="select is-fullwidth mb-4">
                            <select value={selectedStatus} onChange={(e) => setSelectedStatus(e.target.value)}>
                                <option value="mulai">Mulai</option>
                                <option value="selesai">Selesai</option>
                            </select>
                        </div>
                        <button className="button is-success" onClick={handleStatusUpdate}>Update Status</button>
                        <button className="button" onClick={closeModal}>Cancel</button>
                    </div>
                </div>
                <button className="modal-close is-large" aria-label="close" onClick={closeModal}></button>
            </div>
        </div>
    );
};

export default DiscipleshipJourneyListClass;
