import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import '../style/UserAddStyle.css';
import EditModalLifegroup from '../modals/EditModalLifegroup';
const EditLifegroup = () => {
    const [lifegroupName, setLifegroupName] = useState("");
    const [lifegroupLeader, setLifegroupLeader] = useState("");
    const [lifegroupCategory, setLifegroupCategory] = useState([]);
    const [errors, setErrors] = useState({});
    const [optionPerson, setOptionPerson] = useState([]);
    const [selectedPerson, setSelectedPerson] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false); // Modal state

    const { id } = useParams();
    const navigate = useNavigate();

    const [updatedData, setUpdatedData] = useState({});

    const getUserEdit = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewLifeGroupById/${id}`);
            const categories = response.data.lifegroupCategory.split(',').map(item => item.trim());
            setLifegroupLeader(response.data.lifegroupLeader);
            setLifegroupCategory(categories);
            setLifegroupName(response.data.lifegroupName);

            const personResponse = await axios.get(`${process.env.REACT_APP_API_URL}/viewPerson`);
            const options = personResponse.data.map((dj) => ({
                value: dj.personId,
                label: dj.namaDepan,
            }));
            setOptionPerson(options);
            setSelectedPerson(options.find(option => option.value === response.data.lifegroupLeader));
        } catch (error) {
            console.error("Error fetching user data:", error);
            alert('Lifegroup ID not found. Redirecting to home page.');
            navigate('/');
        }
    };

    useEffect(() => {
        getUserEdit();
    }, []);

    const handlePersonChange = (selectedOption) => {
        setSelectedPerson(selectedOption);
        setLifegroupLeader(selectedOption.value);
    };

    const handleCheckboxChange = (category) => {
        setLifegroupCategory((prevCategories) => 
            prevCategories.includes(category)
                ? prevCategories.filter((c) => c !== category)
                : [...prevCategories, category]
        );
    };

    const validate = () => {
        const newErrors = {};
        if (lifegroupCategory.length === 0) {
            newErrors.lifegroupCategory = "Please select at least one category.";
        }
        setErrors(newErrors);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        validate();

        if (Object.keys(errors).length === 0) {
            const updatedData = {
                lifegroupName,
                lifegroupLeader: selectedPerson?.label || '',
                lifegroupCategory: lifegroupCategory.join(', '),
            };

            setIsModalOpen(true);
            setUpdatedData(updatedData);  // Simpan data baru yang diedit
        }
    };

    // Confirm form submission inside modal
    const handleConfirm = async () => {
        setIsModalOpen(false); // Close modal

        try {
            const userData = {
                lifegroupName,
                lifegroupLeader,
                lifegroupCategory: lifegroupCategory.join(', '),
            };

            const filteredUserData = Object.fromEntries(
                Object.entries(userData).filter(([_, value]) => value !== null && value !== undefined && value !== "")
            );

            await axios.patch(`${process.env.REACT_APP_API_URL}/updateLifeGroup/${id}`, filteredUserData);
            navigate("/");
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div className="columns is-mobile mr-2">
            <div className="column ">
                <div className='is-size-4 mb-3 has-text-weight-bold'>Life Group Information</div>
                <hr/>
                <form onSubmit={handleSubmit}>
                    {/* Lifegroup Name */}
                    <div className="columns">
                        <div className="column is-3">
                            <div className='is-size-5 mb-2 has-text-weight-semibold'>Lifegroup Name</div>
                        </div>
                        <div className="column is-3">
                            <label className='control'>
                                <input
                                    type="text"
                                    className='input'
                                    value={lifegroupName}
                                    onChange={(e) => setLifegroupName(e.target.value)}
                                    placeholder='Nama Lifegroup'
                                />
                            </label>
                        </div>
                    </div>

                    {/* Lifegroup Category */}
                    <div className="columns">
                        <div className="column is-3">
                            <div className="is-size-5 mb-2 has-text-weight-semibold">Lifegroup Category</div>
                        </div>
                        <div className="column">
                            <div className="control mb-2">
                                {["SMP", "SMA", "UNI", "Young Pro", "Family", "Senior"].map((category) => (
                                    <label className="checkbox" key={category}>
                                        <input
                                            type="checkbox"
                                            name="lifegroupCategory"
                                            value={category}
                                            checked={lifegroupCategory.includes(category)}
                                            onChange={() => handleCheckboxChange(category)}
                                        />
                                        <span className="checkbox-text">{category}</span>
                                    </label>
                                ))}
                                {errors.lifegroupCategory && <p className="help is-danger">{errors.lifegroupCategory}</p>}
                            </div>
                        </div>
                    </div>

                    {/* Leader Selection */}
                    <div className="columns">
                        <div className="column is-3">
                            <div className='is-size-5 mb-2 has-text-weight-semibold'>Leader</div>
                        </div>
                        <div className="column is-3">
                            <Select
                                value={selectedPerson}
                                onChange={handlePersonChange}
                                options={optionPerson}
                            />
                        </div>
                    </div>

                    {/* Save Button */}
                    <div className="is-flex is-justify-content-flex-end mt-4">
                        <button className='button' style={{
                            backgroundColor: '#0052CC',
                            color: 'white',
                            padding: '10px 24px',
                            fontWeight: 'bold',
                            borderRadius: '8px'
                        }}>
                            Save
                        </button>
                    </div>
                </form>

                <EditModalLifegroup
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    onConfirm={handleConfirm}
                    message="Are you sure you want to save this data?"
                    updatedData={updatedData}  // Kirim data yang diubah ke modal
                />
            </div>
        </div>
    );
};

export default EditLifegroup;
